export enum NumberFormat {
  Compact = 'compact',
  Currency = 'currency',
  CurrencyShort = 'currency-short',
  EmissionFactor = 'emission-factor',
  Integer = 'integer',
  Percentage = 'percentage',
  PhysicalQuantity = 'physical-quantity',
  YearsFractional = 'years-fractional',
  InputCurrency = 'input-currency',
  InputEmissionFactor = 'input-emission-factor',
  InputPercentage = 'input-percentage',
  InputPhysicalQuantity = 'input-physical-quantity',
  InputYearsFractional = 'input-years-fractional',
  InputCurrencyExchangeRate = 'InputCurrencyExchangeRate',
}
